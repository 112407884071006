<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">All Records</div>
  </div>
  <div
    class="search-voter-section-outer"
    style="height: calc(100vh - 141px)"
    id="scrollableDiv"
  >
    <div class="layout-content-section">
      <div class="form-box-outer">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12">
                <div class="custom-form-group">
                  <label class="form-label">Mobile Number</label>
                  <div class="input-group custom-input-group">
                    <div class="col-lg-4 col-md-4 col-12">
                      <Multiselect
                        v-model="countrycode"
                        :options="countryCodeList"
                        :searchable="true"
                        label="label"
                        placeholder="Select"
                        disabled
                        class="multiselect-custom custom-title-multiselect"
                        :canClear="false"
                        :closeOnSelect="true"
                        :object="true"
                        noOptionsText="No Result"
                        :canDeselect="false"
                      />
                    </div>
                    <div class="col-lg-8 col-md-8 col-12">
                      <input
                        type="text"
                        :disabled="disabledinput"
                        v-model="searchmobile"
                        class="form-control"
                        id="formSearchMobileNoInput"
                        placeholder="Enter mobile No."
                        maxlength="11"
                        @keypress="onlyNumberMobile"
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="custom-form-group">
                  <label class="form-label">First Name</label>
                  <div class="custom-form-group">
                    <input
                      type="text"
                      :disabled="disabledinput"
                      v-model="firstname"
                      class="form-control text-capitalize"
                      placeholder="Enter First Name"
                      maxlength="20"
                      @keypress="isLetterWithOutSpace($event)"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-1 col-md-1 col-2">
                <Divider layout="vertical"><b>OR</b></Divider>
              </div>
              <div class="col-lg-3 col-md-3 col-12">
                <div class="custom-form-group">
                  <label for="formpancardinput" class="form-label">PAN Number</label>
                  <input
                    type="text"
                    v-model="pancardnumber"
                    class="form-control text-uppercase"
                    id="formpancardinput"
                    :disabled="disabledinput"
                    placeholder="Enter PAN Number"
                    autocomplete="off"
                    maxlength="10"
                  />
                  <span class="custom-error" v-if="errpan">
                    {{ errpan }}
                  </span>
                  <span class="custom-error" v-if="errmsg">
                    {{ errmsg }}
                  </span>
                </div>
              </div>
              <div class="col-lg-2 col-md-2 col-12">
                <div class="custom-form-group" style="margin-top: 26px">
                  <label class="form-label"></label>
                  <button
                    type="button"
                    class="header-btn-outer btn btn-primary"
                    :style="{ width: '100px' }"
                    @click="searchmobilehistory()"
                    :disabled="
                      (searchmobile.length < 10 || disabledinput) &&
                      (pancardnumber.length < 10 || errStatus)
                    "
                  >
                    <span v-if="!btnloader">Search</span>
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="btnloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-12">
            <div class="row">
              <div class="col-lg-3 col-md-3 col-12" v-if="this.masterdata != ''">
                <div class="custom-form-group" v-if="this.searchmobile != ''">
                  <label class="form-label">Possible Occupation</label>
                  <div class="custom-form-group">
                    <span
                      class="occupation-value blink-occupation-value"
                      v-if="dindetailsresponse && dindetailsresponse.data.din_number"
                      >Business</span
                    >
                    <span
                      class="occupation-value blink-occupation-value"
                      v-else-if="uaninforesponse && uaninforesponse.data.pf_uan"
                      >Salaried</span
                    >
                    <span v-else>N/A</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 col-md-9 col-12 text-end">
                <div class="custom-form-group" style="margin-top: 15px">
                  <button
                    v-if="localdatafound"
                    type="button"
                    class="btn form-submit-btn me-3"
                    :style="{ width: '130px' }"
                    @click="deletelocaldata()"
                    :disabled="localbtnloader"
                  >
                    <span v-if="!localbtnloader"
                      ><i class="pi pi-trash me-1" style="font-size: 0.7rem"></i>Delete
                      Data</span
                    >
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="localbtnloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <button
                    v-if="clearbtnflag"
                    type="button"
                    class="btn custom-view-detail-btn me-3"
                    @click="searchvoterclearbtn()"
                  >
                    Clear All
                  </button>
                  <button
                    v-if="this.masterdata != ''"
                    type="button"
                    class="btn print-submit-btn"
                    :style="{ width: '130px' }"
                    @click="printToPdfdata()"
                    :disabled="printbtnloader"
                  >
                    <span v-if="!printbtnloader"
                      ><i class="pi pi-print me-1" style="font-size: 0.7rem"></i>Print to
                      PDF</span
                    >
                    <div
                      class="spinner-border text-light custom-spinner-loader-btn"
                      role="status"
                      v-if="printbtnloader"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="search-tab-outer-scroll" v-if="mobiledetailshowflag">
          <div v-if="this.masterdata != ''">
            <div v-if="prefillresponse">
              <div class="position-relative" v-if="pre_personal_info.length != 0">
                <div class="voter-exit-box-outer mb-3" v-if="!showprepersonalinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label"
                          >Personal Information</label
                        >
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Full Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                pre_personal_info.full_name
                                  ? pre_personal_info.full_name
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Date of Birth</label>
                            <div class="from-label-value">
                              {{ format_date(pre_personal_info.dob) }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Gender</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                pre_personal_info.gender
                                  ? pre_personal_info.gender
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <Divider type="dashed" class="mt-0 mb-2" />
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Age</label>
                            <div class="from-label-value">
                              {{ pre_personal_info.age ? pre_personal_info.age : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 col-md-4 col-12"
                          v-if="pre_personal_info.total_income != ''"
                        >
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Total Income</label>
                            <div class="from-label-value">
                              {{
                                vueNumberFormat(pre_personal_info.total_income, {
                                  prefix: "₹ ",
                                  decimal: ".",
                                  thousand: ",",
                                  precision: 0,
                                  acceptNegative: true,
                                })
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Reported Income From</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                pre_personal_info.occupation
                                  ? pre_personal_info.occupation
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showprepersonalinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Personal Information ...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_phone_info != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!showprephoneinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Contact Details</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row mb-2"
                        v-for="(phoneinfo, index) in pre_phone_info"
                        :key="phoneinfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_phone_info.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Number</label>
                            <div class="from-label-value">
                              {{ phoneinfo.number ? phoneinfo.number : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <Divider class="mt-0 mb-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showprephoneinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Contact Details...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_email_info != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!showpreemaildetailloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Email Addresses</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row mb-2"
                        v-for="(emailinfo, index) in pre_email_info"
                        :key="emailinfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_email_info.length - 1,
                        }"
                      >
                        <div
                          class="col-lg-4 col-md-4 col-12"
                          v-if="emailinfo.email_address"
                        >
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value">
                              {{
                                emailinfo.email_address ? emailinfo.email_address : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <Divider class="mt-0 mb-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showpreemaildetailloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Email Address...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_address_info.length != 0">
                <div class="voter-exit-box-outer mb-3" v-if="!showpreaddressdetailloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Addresses</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row mb-2"
                        v-for="(addressinfo, index) in pre_address_info"
                        :key="addressinfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_address_info.length - 1,
                        }"
                      >
                        <div class="col-lg-8 col-md-8 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Address</label>
                            <div class="from-label-value">
                              {{ addressinfo.address ? addressinfo.address : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">State</label>
                            <div class="from-label-value text-capitalize">
                              {{ addressinfo.state ? addressinfo.state : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Postal</label>
                            <div class="from-label-value">
                              {{ addressinfo.postal ? addressinfo.postal : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-4 col-md-4 col-12"
                          v-if="addressinfo.reported_date"
                        >
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Date</label>
                            <div class="from-label-value">
                              {{ format_date(addressinfo.reported_date) }}
                            </div>
                          </div>
                        </div>
                        <Divider class="mt-0 mb-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showpreaddressdetailloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Addresses...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_pan_info.length != 0">
                <div class="voter-exit-box-outer mb-3" v-if="!showprepandetailloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">PAN Number</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(paninfo, index) in pre_pan_info"
                        :key="paninfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_pan_info.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value text-uppercase">
                              {{ paninfo.id_number ? paninfo.id_number : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showprepandetailloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching PAN Number...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_aadhaar_info.length != 0">
                <div class="voter-exit-box-outer mb-3" v-if="!showpreaadhaardetailloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Aadhaar Number</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(aadhaarinfo, index) in pre_aadhaar_info"
                        :key="aadhaarinfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_aadhaar_info.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value">
                              {{ aadhaarinfo.id_number ? aadhaarinfo.id_number : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showpreaadhaardetailloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Aadhaar Number...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_passport_info.length != 0">
                <div class="voter-exit-box-outer mb-3" v-if="!showprepassportinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Passport Number</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(passportinfo, index) in pre_passport_info"
                        :key="passportinfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_passport_info.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Id Number</label>
                            <div class="from-label-value text-uppercase">
                              {{
                                passportinfo.id_number ? passportinfo.id_number : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showprepassportinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Passport Number...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_ration_card_info.length != 0">
                <div
                  class="voter-exit-box-outer mb-3"
                  v-if="!showprerationcardinfoloader"
                >
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Ration Card Number</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(rationcardinfo, index) in pre_ration_card_info"
                        :key="rationcardinfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_ration_card_info.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Id Number</label>
                            <div class="from-label-value text-uppercase">
                              {{
                                rationcardinfo.id_number
                                  ? rationcardinfo.id_number
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showprerationcardinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Ration Card Number...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="pre_other_id_info.length != 0">
                <div class="voter-exit-box-outer mb-3" v-if="!showpreotheridinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Other Id's</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(otheridinfo, index) in pre_other_id_info"
                        :key="otheridinfo"
                        v-bind:class="{
                          dividerrowhide: index == pre_other_id_info.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Id Number</label>
                            <div class="from-label-value text-uppercase">
                              {{ otheridinfo.id_number ? otheridinfo.id_number : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showpreotheridinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Other Possible Id's...</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="position-relative" v-if="showfulldrivinginfo.length != 0">
              <div
                class="voter-exit-box-outer mb-3"
                v-if="!showpredrivinglicenseinfoloader"
              >
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="individual-header-label"
                        >Driving License Details</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div
                      class="row"
                      v-for="(drivinginfo, index) in showfulldrivinginfo"
                      :key="drivinginfo"
                      v-bind:class="{
                        dividerrowhide: index == showfulldrivinginfo.length - 1,
                      }"
                    >
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">License Number</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              drivinginfo.license_number
                                ? drivinginfo.license_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Name</label>
                          <div class="from-label-value text-capitalize">
                            {{ drivinginfo.name ? drivinginfo.name : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Father / Husband Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              drivinginfo.father_or_husband_name
                                ? drivinginfo.father_or_husband_name
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Birth</label>
                          <div class="from-label-value">
                            {{ format_date(drivinginfo.dob) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Blood Group</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              drivinginfo.blood_group ? drivinginfo.blood_group : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value text-capitalize">
                            {{ drivinginfo.gender ? drivinginfo.gender : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-4 col-md-4 col-12"
                        v-if="drivinginfo.citizenship"
                      >
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Citizenship</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              drivinginfo.citizenship ? drivinginfo.citizenship : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12" v-if="drivinginfo.ola_name">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Name</label>
                          <div class="from-label-value text-capitalize">
                            {{ drivinginfo.ola_name ? drivinginfo.ola_name : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12" v-if="drivinginfo.ola_code">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Code</label>
                          <div class="from-label-value text-capitalize">
                            {{ drivinginfo.ola_code ? drivinginfo.ola_code : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-4 col-md-4 col-12"
                        v-if="drivinginfo.vehicle_classes"
                      >
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Vehicle Classes</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              drivinginfo.vehicle_classes
                                ? drivinginfo.vehicle_classes
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Issue</label>
                          <div class="from-label-value">
                            {{ format_date(drivinginfo.doi) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Expiry</label>
                          <div class="from-label-value">
                            {{ format_date(drivinginfo.doe) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Transport Date of Issue</label>
                          <div class="from-label-value">
                            {{ format_date(drivinginfo.transport_doi) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Transport Date of Expiry</label>
                          <div class="from-label-value">
                            {{ format_date(drivinginfo.transport_doe) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Address</label>
                          <div class="from-label-value">
                            {{
                              drivinginfo.temporary_address
                                ? drivinginfo.temporary_address
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Zip</label>
                          <div class="from-label-value">
                            {{
                              drivinginfo.temporary_zip
                                ? drivinginfo.temporary_zip
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Address</label>
                          <div class="from-label-value">
                            {{
                              drivinginfo.permanent_address
                                ? drivinginfo.permanent_address
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Zip</label>
                          <div class="from-label-value">
                            {{
                              drivinginfo.permanent_zip
                                ? drivinginfo.permanent_zip
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">State</label>
                          <div class="from-label-value text-capitalize">
                            {{ drivinginfo.state ? drivinginfo.state : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <Divider class="mt-0 mb-2" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showpredrivinglicenseinfoloader"
                style="height: 150px; margin: 16px 16px 0"
              >
                <div class="custom-allrecords-spinner-loader">
                  <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                  <span class="spinner-text">Searching Driving License Details...</span>
                </div>
              </div>
            </div>
            <div class="position-relative" v-if="showpantodininfo != ''">
              <div class="voter-exit-box-outer mb-3" v-if="!showpantodininfoloader">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="individual-header-label">DIN Number From PAN</label>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">DIN Number</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              showpantodininfo.data.din_number
                                ? showpantodininfo.data.din_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">PAN Number</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              showpantodininfo.data.pan_number
                                ? showpantodininfo.data.pan_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showpantodininfoloader"
                style="height: 150px; margin: 16px 16px 0"
              >
                <div class="custom-allrecords-spinner-loader">
                  <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                  <span class="spinner-text">Searching DIN Number...</span>
                </div>
              </div>
            </div>
            <div class="position-relative" v-if="showdindetailinfo != ''">
              <div class="voter-exit-box-outer mb-3" v-if="!showdindetailinfoloader">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="individual-header-label">DIN Details</label>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">DIN</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              showdindetailinfo.data.din_number
                                ? showdindetailinfo.data.din_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Mobile Number</label>
                          <div class="from-label-value">
                            {{
                              showdindetailinfo.data.phone_number
                                ? showdindetailinfo.data.phone_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Birth Date</label>
                          <div class="from-label-value">
                            {{ format_date(showdindetailinfo.data.dob) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Email</label>
                          <div class="from-label-value">
                            {{
                              showdindetailinfo.data.email
                                ? showdindetailinfo.data.email
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Fathers Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showdindetailinfo.data.father_name
                                ? showdindetailinfo.data.father_name
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Full Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showdindetailinfo.data.full_name
                                ? showdindetailinfo.data.full_name
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Nationality</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showdindetailinfo.data.nationality
                                ? showdindetailinfo.data.nationality
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">PAN Number</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              showdindetailinfo.data.pan_number
                                ? showdindetailinfo.data.pan_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Address</label>
                          <div class="from-label-value">
                            {{
                              showdindetailinfo.data.permanent_address
                                ? showdindetailinfo.data.permanent_address
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Present Address</label>
                          <div class="from-label-value">
                            {{
                              showdindetailinfo.data.present_address
                                ? showdindetailinfo.data.present_address
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showdindetailinfoloader"
                style="height: 150px; margin: 16px 16px 0"
              >
                <div class="custom-allrecords-spinner-loader">
                  <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                  <span class="spinner-text"
                    >Searching DIN Details Through MCA Database...</span
                  >
                </div>
              </div>
            </div>
            <div class="position-relative" v-if="showvoterdetailinfo != ''">
              <div class="voter-exit-box-outer mb-3" v-if="!showvoterdetailinfoloader">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="individual-header-label">Voter Id Details</label>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Id Number</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              showvoterdetailinfo.data.epic_no
                                ? showvoterdetailinfo.data.epic_no
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.name
                                ? showvoterdetailinfo.data.name
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Relation Name | Relation Type</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.relation_name
                                ? showvoterdetailinfo.data.relation_name
                                : "N/A"
                            }}<span class="px-1">|</span
                            >{{
                              showvoterdetailinfo.data.relation_type
                                ? showvoterdetailinfo.data.relation_type
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.gender
                                ? showvoterdetailinfo.data.gender
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Birth | Age</label>
                          <div class="from-label-value">
                            {{ format_date(showvoterdetailinfo.data.dob)
                            }}<span class="px-1">|</span
                            >{{
                              showvoterdetailinfo.data.age
                                ? showvoterdetailinfo.data.age
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">House No.</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.house_no
                                ? showvoterdetailinfo.data.house_no
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Area</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.area
                                ? showvoterdetailinfo.data.area
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">District</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.district
                                ? showvoterdetailinfo.data.district
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">State</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.state
                                ? showvoterdetailinfo.data.state
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label"
                            >Parliamentary Constituency | Number</label
                          >
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.parliamentary_constituency
                                ? showvoterdetailinfo.data.parliamentary_constituency
                                : "N/A"
                            }}<span class="px-1">|</span
                            >{{
                              showvoterdetailinfo.data.parliamentary_number
                                ? showvoterdetailinfo.data.parliamentary_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Assembly Constituency | Number</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.assembly_constituency
                                ? showvoterdetailinfo.data.assembly_constituency
                                : "N/A"
                            }}<span class="px-1">|</span
                            >{{
                              showvoterdetailinfo.data.assembly_constituency_number
                                ? showvoterdetailinfo.data.assembly_constituency_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Part Name | Number</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.part_name
                                ? showvoterdetailinfo.data.part_name
                                : "N/A"
                            }}<span class="px-1">|</span
                            >{{
                              showvoterdetailinfo.data.part_number
                                ? showvoterdetailinfo.data.part_number
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Polling Station</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showvoterdetailinfo.data.polling_station
                                ? showvoterdetailinfo.data.polling_station
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showvoterdetailinfoloader"
                style="height: 150px; margin: 16px 16px 0"
              >
                <div class="custom-allrecords-spinner-loader">
                  <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                  <span class="spinner-text">Searching Voter Id Details...</span>
                </div>
              </div>
            </div>
            <div class="position-relative" v-if="showuannumberinfo != ''">
              <div class="voter-exit-box-outer mb-3" v-if="!showuannumberinfoloader">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="individual-header-label"
                        >UAN - Universal Account Number allotted by Employees’ Fund
                        Organization (EPFO)</label
                      >
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">UAN</label>
                          <div class="from-label-value text-uppercase">
                            {{
                              showuannumberinfo.data.pf_uan
                                ? showuannumberinfo.data.pf_uan
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showuannumberinfoloader"
                style="height: 150px; margin: 16px 16px 0"
              >
                <div class="custom-allrecords-spinner-loader">
                  <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                  <span class="spinner-text"
                    >Searching UAN - Universal Account Number...</span
                  >
                </div>
              </div>
            </div>
            <div class="position-relative" v-if="showupinumberinfo != ''">
              <div class="voter-exit-box-outer mb-3" v-if="!showupinumberinfoloader">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="individual-header-label">UPI Id</label>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Full Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              showupinumberinfo.data.full_name
                                ? showupinumberinfo.data.full_name
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Mobile Number</label>
                          <div
                            class="from-label-value"
                            v-if="showupinumberinfo.data.mobile_number"
                          >
                            +91-{{ showupinumberinfo.data.mobile_number }}
                          </div>
                          <div class="from-label-value" v-else>N/A</div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">UPI Id</label>
                          <div class="from-label-value">
                            {{
                              showupinumberinfo.data.upi_id
                                ? showupinumberinfo.data.upi_id
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showupinumberinfoloader"
                style="height: 150px; margin: 16px 16px 0"
              >
                <div class="custom-allrecords-spinner-loader">
                  <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                  <span class="spinner-text">Searching UPI Id...</span>
                </div>
              </div>
            </div>
            <div class="position-relative" v-if="showemploymenthistoryinfo.length != 0">
              <div class="voter-exit-box-outer mb-3" v-if="!showemploymenthistoryloader">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <label class="individual-header-label">Employment History</label>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div
                      class="row"
                      v-for="(emphistory, index) in showemploymenthistoryinfo"
                      :key="emphistory"
                      v-bind:class="{
                        dividerrowhide: index == showemploymenthistoryinfo.length - 1,
                      }"
                    >
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Joining</label>
                          <div class="from-label-value">
                            {{ format_date(emphistory.date_of_joining) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Exit</label>
                          <div class="from-label-value">
                            {{ format_date(emphistory.date_of_exit) }}
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Employer Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              emphistory.establishment_name
                                ? emphistory.establishment_name
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Guardian Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              emphistory.guardian_name ? emphistory.guardian_name : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Member Id</label>
                          <div class="from-label-value text-uppercase">
                            {{ emphistory.member_id ? emphistory.member_id : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Name</label>
                          <div class="from-label-value text-capitalize">
                            {{ emphistory.name ? emphistory.name : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <Divider class="mt-0 mb-2" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showemploymenthistoryloader"
                style="height: 150px; margin: 16px 16px 0"
              >
                <div class="custom-allrecords-spinner-loader">
                  <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                  <span class="spinner-text">Searching Employment History...</span>
                </div>
              </div>
            </div>
            <div v-if="popshowlinkstatus != ''">
              <Divider class="mt-4 mb-4" />
              <div
                class="individual-header-label text-center blink-occupation-value"
                v-if="deepsearchinglabel"
              >
                Performing Deep Search
              </div>
              <Divider class="mt-4 mb-4" v-if="deepsearchinglabel" />
              <div class="position-relative" v-if="popshowlinkstatus != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popaadhaarlinkloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label"
                          >Aadhaar Linking Status</label
                        >
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Status</label>
                            <div class="from-label-value text-capitalize">
                              {{ popshowlinkstatus ? popshowlinkstatus : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="popaadhaarlinkloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Checking Aadhaar Linking Status...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowaddress != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popaddressloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Address</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Address</label>
                            <div class="from-label-value text-capitalize">
                              {{ popshowaddress.full ? popshowaddress.full : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="popaddressloader" style="height: 150px; margin: 16px 16px 0">
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching More Possible Addresses...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowdincompany != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popdincompanyloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">DIN Details</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">DIN</label>
                            <div class="from-label-value text-capitalize">
                              {{ popshowdincompany.din ? popshowdincompany.din : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">DIN Allocation Date</label>
                            <div class="from-label-value text-capitalize">
                              {{ format_date(popshowdincompany.dinAllocationDate) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(popdininfo, index) in popshowdincompany.company_list"
                        :key="popdininfo"
                        v-bind:class="{
                          dividerrowhide:
                            index == popshowdincompany.company_list.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">CIN</label>
                            <div class="from-label-value text-uppercase">
                              {{ popdininfo.cin ? popdininfo.cin : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                popdininfo.company_name ? popdininfo.company_name : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <Divider class="mt-0 mb-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="popdincompanyloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching DIN Details...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowdateofbirth != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popbirthdateloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Date of Birth</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value text-capitalize">
                              {{ format_date(popshowdateofbirth) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="popbirthdateloader" style="height: 150px; margin: 16px 16px 0">
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Finding Date of Birth...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowemailaddress != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popemailidloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Email Id</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value">
                              {{ popshowemailaddress ? popshowemailaddress : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="popemailidloader" style="height: 150px; margin: 16px 16px 0">
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Email Address...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowfullname != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popfullnameloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Full Name</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value text-capitalize">
                              {{ popshowfullname ? popshowfullname : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="popfullnameloader" style="height: 150px; margin: 16px 16px 0">
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Finding Full Name...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowgender != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popgenderloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Gender</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value text-capitalize">
                              {{ popshowgender ? popshowgender : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="popgenderloader" style="height: 150px; margin: 16px 16px 0">
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Identifying Gender...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowdirectorhistory != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popdirectorinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Director Details</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(popdirectorinfo, index) in popshowdirectorhistory"
                        :key="popdirectorinfo"
                        v-bind:class="{
                          dividerrowhide: index == popshowdirectorhistory.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Aggregate Turn Over</label>
                            <div class="from-label-value text-uppercase">
                              {{
                                popdirectorinfo.aggregate_turn_over
                                  ? popdirectorinfo.aggregate_turn_over
                                  : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">GST</label>
                            <div class="from-label-value text-capitalize">
                              {{ popdirectorinfo.gst ? popdirectorinfo.gst : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <Divider class="mt-0 mb-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="popdirectorinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching Director Information...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowmaskedaadhaar != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popmaskedaadhaarinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Aadhaar Number</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <div class="from-label-value text-capitalize">
                              {{ popshowmaskedaadhaar ? popshowmaskedaadhaar : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="popmaskedaadhaarinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Checking For Aadhaar Number...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowpannumber != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!poppannumberinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">PAN Number</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Number</label>
                            <div class="from-label-value text-capitalize">
                              {{ popshowpannumber ? popshowpannumber : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name as per PAN</label>
                            <div class="from-label-value text-capitalize">
                              {{ popshowfullname ? popshowfullname : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">DOB as per PAN</label>
                            <div class="from-label-value text-capitalize">
                              {{ format_date(popshowdateofbirth) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="poppannumberinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Checking PAN Number Again...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="popshowphonenumber != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!popphonenumberinfoloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Contact Details</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Number</label>
                            <div class="from-label-value text-capitalize">
                              {{ popshowphonenumber ? popshowphonenumber : "N/A" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="popphonenumberinfoloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching More Contact Details...</span>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="showrcnumberhistoryinfo != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!showrcnumberhistoryloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label"
                          >Possible Registered Vehicles</label
                        >
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(poprcnumberinfo, index) in showrcnumberhistoryinfo"
                        :key="poprcnumberinfo"
                        v-bind:class="{
                          dividerrowhide: index == showrcnumberhistoryinfo.length - 1,
                        }"
                      >
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Vehicle Number</label>
                            <div class="from-label-value text-uppercase">
                              {{ poprcnumberinfo ? poprcnumberinfo : "N/A" }}
                            </div>
                          </div>
                        </div>
                        <Divider class="mt-0 mb-2" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showrcnumberhistoryloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text"
                      >Searching Possible Registered Vehicles Information...</span
                    >
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="showrcnumberdetailhistoryinfo != ''">
                <div
                  class="voter-exit-box-outer mb-3"
                  v-if="!showrcnumberdetailhistoryloader"
                >
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label"
                          >Possible Registered Vehicle Details</label
                        >
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(poprcdeatilinfo, index) in showrcnumberdetailhistoryinfo"
                        :key="poprcdeatilinfo"
                        v-bind:class="{
                          dividerrowhide:
                            index == showrcnumberdetailhistoryinfo.length - 1,
                        }"
                      >
                        <div class="col-12">
                          <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Vehicle Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.rc_number
                                      ? poprcdeatilinfo.data.rc_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Owner Name</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.owner_name
                                      ? poprcdeatilinfo.data.owner_name
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">RC Status</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.rc_status
                                      ? poprcdeatilinfo.data.rc_status
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Mobile Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.mobile_number
                                      ? poprcdeatilinfo.data.mobile_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Father / Husband Name</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.father_name
                                      ? poprcdeatilinfo.data.father_name
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Owner Serial</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.owner_number
                                      ? poprcdeatilinfo.data.owner_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Maker Description</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.maker_description
                                      ? poprcdeatilinfo.data.maker_description
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Body Type</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.body_type
                                      ? poprcdeatilinfo.data.body_type
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Color</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.color
                                      ? poprcdeatilinfo.data.color
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Fit Upto</label>
                                <div class="from-label-value text-uppercase">
                                  {{ format_date(poprcdeatilinfo.data.fit_up_to) }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Fuel Type</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.fuel_type
                                      ? poprcdeatilinfo.data.fuel_type
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Cylinders</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.no_cylinders
                                      ? poprcdeatilinfo.data.no_cylinders
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Chassis Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.vehicle_chasi_number
                                      ? poprcdeatilinfo.data.vehicle_chasi_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Engine Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.vehicle_engine_number
                                      ? poprcdeatilinfo.data.vehicle_engine_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Cubic Capacity</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.cubic_capacity
                                      ? poprcdeatilinfo.data.cubic_capacity
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Registered At</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.registered_at
                                      ? poprcdeatilinfo.data.registered_at
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Registration Date</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    format_date(poprcdeatilinfo.data.registration_date)
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Manufacturing Date</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.manufacturing_date
                                      ? poprcdeatilinfo.data.manufacturing_date
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Permanent Address</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.permanent_address
                                      ? poprcdeatilinfo.data.permanent_address
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Present Address</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.present_address
                                      ? poprcdeatilinfo.data.present_address
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label"
                                  >Vehicle Category Description</label
                                >
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.vehicle_category_description
                                      ? poprcdeatilinfo.data.vehicle_category_description
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.variant"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Variant</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.variant
                                      ? poprcdeatilinfo.data.variant
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Vehicle Category</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.vehicle_category
                                      ? poprcdeatilinfo.data.vehicle_category
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.blacklist_status"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Blacklist Status</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.blacklist_status
                                      ? poprcdeatilinfo.data.blacklist_status
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.challan_details"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Challan Details</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.challan_details
                                      ? poprcdeatilinfo.data.challan_details
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Seat Capacity</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.seat_capacity
                                      ? poprcdeatilinfo.data.seat_capacity
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.sleeper_capacity"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Sleeper Capacity</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.sleeper_capacity
                                      ? poprcdeatilinfo.data.sleeper_capacity
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.standing_capacity"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Standing Capacity</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.standing_capacity
                                      ? poprcdeatilinfo.data.standing_capacity
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.norms_type"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Norms Type</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.norms_type
                                      ? poprcdeatilinfo.data.norms_type
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              poprcdeatilinfo.data.financed ||
                              poprcdeatilinfo.data.financer
                            "
                          >
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Financed Status</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.financed
                                      ? poprcdeatilinfo.data.financed
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Financer</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.financer
                                      ? poprcdeatilinfo.data.financer
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              poprcdeatilinfo.data.insurance_company ||
                              poprcdeatilinfo.data.insurance_policy_number
                            "
                          >
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.insurance_company"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Insurance Company</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.insurance_company
                                      ? poprcdeatilinfo.data.insurance_company
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.insurance_policy_number"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Insurance Policy Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.insurance_policy_number
                                      ? poprcdeatilinfo.data.insurance_policy_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Latest By</label>
                                <div class="from-label-value text-uppercase">
                                  {{ format_date(poprcdeatilinfo.data.latest_by) }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row" v-if="poprcdeatilinfo.data.pucc_number">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">PUCC Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.pucc_number
                                      ? poprcdeatilinfo.data.pucc_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">PUCC Upto</label>
                                <div class="from-label-value text-uppercase">
                                  {{ format_date(poprcdeatilinfo.data.pucc_upto) }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.vehicle_gross_weight"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Vehicle Gross Weight</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.vehicle_gross_weight
                                      ? poprcdeatilinfo.data.vehicle_gross_weight
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.wheelbase"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Wheelbase</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.wheelbase
                                      ? poprcdeatilinfo.data.wheelbase
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.unladen_weight"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Unladen Weight</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.unladen_weight
                                      ? poprcdeatilinfo.data.unladen_weight
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.permit_issue_date"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Permit Issue Date</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    format_date(poprcdeatilinfo.data.permit_issue_date)
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.permit_number"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Permit Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.permit_number
                                      ? poprcdeatilinfo.data.permit_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.permit_type"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Permit Type</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.permit_type
                                      ? poprcdeatilinfo.data.permit_type
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.permit_valid_from"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Permit Valid From</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    format_date(poprcdeatilinfo.data.permit_valid_from)
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.permit_valid_upto"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Permit Valid Upto</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    format_date(poprcdeatilinfo.data.permit_valid_upto)
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.national_permit_issued_by"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label"
                                  >National Permit Issued By</label
                                >
                                <div class="from-label-value text-uppercase">
                                  {{
                                    format_date(
                                      poprcdeatilinfo.data.national_permit_issued_by
                                    )
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.national_permit_number"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">National Permit Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.national_permit_number
                                      ? poprcdeatilinfo.data.national_permit_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.national_permit_upto"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">National Permit Upto</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    format_date(poprcdeatilinfo.data.national_permit_upto)
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.noc_details"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Noc Details</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.noc_details
                                      ? poprcdeatilinfo.data.noc_details
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.non_use_from"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Non Use From</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.non_use_from
                                      ? poprcdeatilinfo.data.non_use_from
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.non_use_status"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Non Use Status</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.non_use_status
                                      ? poprcdeatilinfo.data.non_use_status
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-lg-4 col-md-4 col-12"
                              v-if="poprcdeatilinfo.data.non_use_to"
                            >
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Non Use To</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    poprcdeatilinfo.data.non_use_to
                                      ? poprcdeatilinfo.data.non_use_to
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Divider class="mt-2 mb-4 marked" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showrcnumberdetailhistoryloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text"
                      >Searching Possible Registered Vehicle Details...</span
                    >
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="showudyamdetailhistoryinfo != ''">
                <div
                  class="voter-exit-box-outer mb-3"
                  v-if="!showudyamdetailhistoryloader"
                >
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Udyam Details</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(udyamdetails, index) in showudyamdetailhistoryinfo.result"
                        :key="udyamdetails"
                        v-bind:class="{
                          dividerrowhide: index == showudyamdetailhistoryinfo.length - 1,
                        }"
                      >
                        <div class="col-12">
                          <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Udyam Number</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    udyamdetails.udyam_number
                                      ? udyamdetails.udyam_number
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Enterprise Name</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    udyamdetails.enterprise_name
                                      ? udyamdetails.enterprise_name
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="showudyamdetailhistoryloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text">Searching for Udyam Details...</span>
                  </div>
                </div>
              </div>

              <div class="position-relative" v-if="creditdetailaddressinfo != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!creditdetailaddressloader">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="customer-label-group mb-2">
                        <label class="individual-header-label">Credit Score</label>
                        <div class="from-label-value text-uppercase">
                          {{ creditdetailaddressinfo.credit_score["FCIREXScore"] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="position-relative" v-if="creditdetailaddressinfo != ''">
                <div class="voter-exit-box-outer mb-3" v-if="!creditdetailaddressloader">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                      <div class="custom-form-group">
                        <label class="individual-header-label">Addresses</label>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-12">
                      <div
                        class="row"
                        v-for="(
                          creditadd, i
                        ) in creditdetailaddressinfo.CAIS_Holder_Address_Details"
                        :key="i"
                      >
                        <div class="col-12">
                          <div
                            class="row"
                            v-for="(address, index) in creditadd"
                            :key="index"
                            v-bind:class="{
                              dividerrowhide: index == creditadd.length - 1,
                            }"
                          >
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Address</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    address.First_Line_Of_Address_non_normalized
                                      ? address.First_Line_Of_Address_non_normalized
                                      : "N/A"
                                  }}
                                  {{
                                    address.Second_Line_Of_Address_non_normalized
                                      ? address.Second_Line_Of_Address_non_normalized
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">City</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    address.City_non_normalized
                                      ? address.City_non_normalized
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                              <div class="customer-label-group mb-2">
                                <label class="form-label">Postal</label>
                                <div class="from-label-value text-uppercase">
                                  {{
                                    address.ZIP_Postal_Code_non_normalized
                                      ? address.ZIP_Postal_Code_non_normalized
                                      : "N/A"
                                  }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="creditdetailaddressloader"
                  style="height: 150px; margin: 16px 16px 0"
                >
                  <div class="custom-allrecords-spinner-loader">
                    <i class="pi pi-spin pi-cog" style="font-size: 1.5rem"></i>
                    <span class="spinner-text"
                      >Searching for Credit Score And More Addresses Again...</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="voter-exit-box-outer pb-3" v-if="this.masterdata == ''">
            <div>No records found.</div>
          </div>
        </div>
        <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
          <div>No records found.</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import moment from "moment";
export default {
  data() {
    return {
      v$: useValidate(),
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      searchmobile: "",
      btnloader: false,
      disabledinput: false,
      mobiledetailshowflag: false,
      uanhistoryresponse: "",
      rcnumberdetailinallresponse: "",
      rcdetailinallresponse: "",
      inquiry_request_info: "",
      ccr_response: "",
      identity_info_email_address_info: [],
      id_and_contact_info: "",
      nodatafoundflag: false,
      localdatafound: false,
      localbtnloader: false,
      upidataresponse: "",
      prefillresponse: "",
      filldrvingdetailsresponse: "",
      dindetailsresponse: "",
      filldindetailsresponse: "",
      uaninforesponse: "",
      ecivoterinforesponse: "",
      paninallresponse: "",
      firstname: "",
      pancardnumber: "",
      errpan: "",
      errmsg: "",
      errStatus: false,
      masterdata: "",
      clearbtnflag: "",
      printbtnloader: false,

      pre_personal_info: "",
      showprepersonalinfoloader: false,
      pre_phone_info: "",
      showprephoneinfoloader: false,
      pre_email_info: "",
      showpreemaildetailloader: false,
      pre_address_info: "",
      showpreaddressdetailloader: false,
      pre_pan_info: "",
      showprepandetailloader: false,
      pre_aadhaar_info: "",
      showpreaadhaardetailloader: false,
      showfulldrivinginfo: "",
      showpredrivinglicenseinfoloader: false,
      pre_passport_info: "",
      showprepassportinfoloader: false,
      pre_ration_card_info: "",
      showprerationcardinfoloader: false,
      pre_other_id_info: "",
      showpreotheridinfoloader: false,
      showpantodininfo: "",
      showpantodininfoloader: false,
      showdindetailinfo: "",
      showdindetailinfoloader: false,
      showvoterdetailinfo: "",
      showvoterdetailinfoloader: false,
      showuannumberinfo: "",
      showuannumberinfoloader: false,
      showupinumberinfo: "",
      showupinumberinfoloader: false,
      showemploymenthistoryinfo: "",
      showemploymenthistoryloader: false,

      popshowlinkstatus: "",
      popaadhaarlinkloader: false,
      popshowaddress: "",
      popaddressloader: false,
      popshowdincompany: "",
      popdincompanyloader: false,
      popshowdateofbirth: "",
      popbirthdateloader: false,
      popshowemailaddress: "",
      popemailidloader: false,
      popshowfullname: "",
      popfullnameloader: false,
      popshowgender: "",
      popgenderloader: false,
      popshowdirectorhistory: "",
      popdirectorinfoloader: false,
      popshowproprietorhistory: "",
      popproprietorinfoloader: false,
      popshowmaskedaadhaar: "",
      popmaskedaadhaarinfoloader: false,
      popshowpannumber: "",
      poppannumberinfoloader: false,
      popshowphonenumber: "",
      popphonenumberinfoloader: false,
      deepsearchinglabel: false,
      showrcnumberhistoryinfo: "",
      showrcnumberhistoryloader: false,
      showrcnumberdetailhistoryinfo: "",
      showrcnumberdetailhistoryloader: "",
      udyamdetailinallresponse: "",
      showudyamdetailhistoryinfo: "",
      showudyamdetailhistoryloader: false,
      creditdetailaddressresponse: "",
      creditdetailaddressinfo: "",
      creditdetailaddressloader: false,
      masterdata_alt_mob: [],
    };
  },
  validations() {
    return {};
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  watch: {
    pancardnumber(pancardnumber) {
      this.errmsg = "";
      this.validatePanCardNumber(pancardnumber);
      if (pancardnumber.length == 0) {
        this.errpan = "";
        this.errStatus = false;
      }
    },
  },
  mounted() {},
  methods: {
    searchmobilehistory() {
      this.btnloader = true;
      let fields = {};
      if (this.pancardnumber != "") {
        fields["pan_number"] = this.pancardnumber;
      } else {
        fields["mobile_number"] = this.searchmobile;
        if (this.firstname) {
          fields["name"] = this.firstname;
        }
      }
      this.ApiService.getMobileMasterDetails(fields).then((data) => {
        if (data.status === true) {
          if (data.datastatus == 1) {
            this.localdatafound = true;
          } else {
            this.localdatafound = false;
          }
          this.btnloader = false;
          this.masterdata = data.data;
          this.masterdata_alt_mob = data.alt_data;
          if (this.masterdata != "") {
            if (this.pancardnumber == "" && this.searchmobile != "") {
              if (data.data.prefill_Details) {
                let checkprefill = JSON.parse(data.data.prefill_Details);
                if (checkprefill.success == true) {
                  this.prefillresponse = JSON.parse(data.data.prefill_Details);
                  if (this.prefillresponse.data.personal_info.length != 0) {
                    this.pre_personal_info = this.prefillresponse.data.personal_info;
                    this.showprepersonalinfoloader = true;
                    setTimeout(() => {
                      this.prefillphoneinfocall();
                    }, 3000);
                  } else {
                    this.prefillphoneinfocall();
                  }
                } else {
                  this.prefillresponse = "";
                  if (this.masterdata.driving_license) {
                    setTimeout(() => {
                      this.fulldrivinginfocall();
                    }, 2000);
                  } else if (this.masterdata.pan_to_din_response) {
                    setTimeout(() => {
                      this.pantodinnumbercall();
                    }, 2000);
                  } else if (this.masterdata.din_details_response) {
                    setTimeout(() => {
                      this.dininfocall();
                    }, 2000);
                  } else if (this.masterdata.voterid_records) {
                    setTimeout(() => {
                      this.voterinfocall();
                    }, 2000);
                  } else if (this.masterdata.uan_details_response) {
                    setTimeout(() => {
                      this.uannumberinfocall();
                    }, 2000);
                  } else if (this.masterdata.name_details_response) {
                    setTimeout(() => {
                      this.upinumberinfocall();
                    }, 2000);
                  } else if (this.masterdata.employement_uan_response) {
                    setTimeout(() => {
                      this.employmenthistoryinfocall();
                    }, 2000);
                  } else if (this.masterdata.PanAllInOne) {
                    setTimeout(() => {
                      this.poptoaadhaarlinkstatuscall();
                    }, 2000);
                  }
                }
              } else {
                this.prefillresponse = "";
                if (this.masterdata.driving_license) {
                  setTimeout(() => {
                    this.fulldrivinginfocall();
                  }, 2000);
                } else if (this.masterdata.pan_to_din_response) {
                  setTimeout(() => {
                    this.pantodinnumbercall();
                  }, 2000);
                } else if (this.masterdata.din_details_response) {
                  setTimeout(() => {
                    this.dininfocall();
                  }, 2000);
                } else if (this.masterdata.voterid_records) {
                  setTimeout(() => {
                    this.voterinfocall();
                  }, 2000);
                } else if (this.masterdata.uan_details_response) {
                  setTimeout(() => {
                    this.uannumberinfocall();
                  }, 2000);
                } else if (this.masterdata.name_details_response) {
                  setTimeout(() => {
                    this.upinumberinfocall();
                  }, 2000);
                } else if (this.masterdata.employement_uan_response) {
                  setTimeout(() => {
                    this.employmenthistoryinfocall();
                  }, 2000);
                } else if (this.masterdata.PanAllInOne) {
                  setTimeout(() => {
                    this.poptoaadhaarlinkstatuscall();
                  }, 2000);
                }
              }
              if (data.data.driving_license) {
                let checkfulldrivingfill = JSON.parse(data.data.driving_license);
                if (checkfulldrivingfill.success == true) {
                  this.filldrvingdetailsresponse = JSON.parse(data.data.driving_license);
                } else {
                  this.filldrvingdetailsresponse = "";
                }
              }
              if (data.data.pan_to_din_response) {
                let checkdinfill = JSON.parse(data.data.pan_to_din_response);
                if (checkdinfill.success == true) {
                  this.dindetailsresponse = JSON.parse(data.data.pan_to_din_response);
                } else {
                  this.dindetailsresponse = "";
                }
              }
              if (data.data.din_details_response) {
                let checkpandinfill = JSON.parse(data.data.din_details_response);
                if (checkpandinfill.success == true) {
                  this.filldindetailsresponse = JSON.parse(
                    data.data.din_details_response
                  );
                } else {
                  this.filldindetailsresponse = "";
                }
              }
              if (data.data.voterid_records) {
                let checkvoterrecords = JSON.parse(data.data.voterid_records);
                if (checkvoterrecords.success == true) {
                  this.ecivoterinforesponse = JSON.parse(data.data.voterid_records);
                } else {
                  this.ecivoterinforesponse = "";
                }
              }
              if (data.data.uan_details_response) {
                let checkunadetail = JSON.parse(data.data.uan_details_response);
                if (checkunadetail.success == true) {
                  this.uaninforesponse = JSON.parse(data.data.uan_details_response);
                } else {
                  this.uaninforesponse = "";
                }
              }
              if (data.data.name_details_response) {
                let checknamedetails = JSON.parse(data.data.name_details_response);
                if (checknamedetails.success == true) {
                  this.upidataresponse = JSON.parse(data.data.name_details_response);
                } else {
                  this.upidataresponse = "";
                }
              }
              if (data.data.employement_uan_response) {
                let checkemployement = JSON.parse(data.data.employement_uan_response);
                if (checkemployement.success == true) {
                  this.uanhistoryresponse = JSON.parse(
                    data.data.employement_uan_response
                  );
                } else {
                  this.uanhistoryresponse = "";
                }
              }
              if (data.data.PanAllInOne) {
                let checkpaninall = JSON.parse(data.data.PanAllInOne);
                if (checkpaninall.code == 200) {
                  this.paninallresponse = JSON.parse(data.data.PanAllInOne);
                } else {
                  this.paninallresponse = "";
                }
              }
              if (data.data.rc_number_details) {
                let checkrcnumberdetailinall = JSON.parse(data.data.rc_number_details);
                if (checkrcnumberdetailinall.success == true) {
                  this.rcnumberdetailinallresponse = JSON.parse(
                    data.data.rc_number_details
                  );
                } else {
                  this.rcnumberdetailinallresponse = "";
                }
              }
              if (data.data.rc_details_response) {
                let checkrcdetailinall = JSON.parse(data.data.rc_details_response);
                if (checkrcdetailinall.length != "") {
                  this.rcdetailinallresponse = JSON.parse(data.data.rc_details_response);
                } else {
                  this.rcdetailinallresponse = "";
                }
              }
              if (data.data.udyam_response) {
                let checkudyamdetailinall = JSON.parse(data.data.udyam_response);
                if (checkudyamdetailinall.length != "") {
                  this.udyamdetailinallresponse = JSON.parse(data.data.udyam_response);
                } else {
                  this.udyamdetailinallresponse = "";
                }
              }
              if (data.data.credit_score) {
                let creditdetailaddinall = JSON.parse(data.data.credit_score);
                if (creditdetailaddinall.length != "") {
                  this.creditdetailaddressresponse = JSON.parse(data.data.credit_score);
                } else {
                  this.creditdetailaddressresponse = "";
                }
              }
            }
            if (this.pancardnumber != "" && this.searchmobile == "") {
              if (data.data.PanAllInOne) {
                let checkpaninall = JSON.parse(data.data.PanAllInOne);
                if (checkpaninall.code == 200) {
                  this.paninallresponse = JSON.parse(data.data.PanAllInOne);
                  setTimeout(() => {
                    this.poptoaadhaarlinkstatuscall();
                  }, 2000);
                } else {
                  this.paninallresponse = "";
                }
              }
            }
          }
          this.clearbtnflag = true;
          this.mobiledetailshowflag = true;
          this.disabledinput = true;
        } else {
          this.btnloader = false;
          this.mobiledetailshowflag = false;
          this.nodatafoundflag = true;
          this.clearbtnflag = false;
        }
      });
    },
    prefillphoneinfocall() {
      this.scrollbottom();
      this.showprepersonalinfoloader = false;
      if (this.prefillresponse.data.phone_info != "") {
        this.pre_phone_info = this.prefillresponse.data.phone_info;
        this.showprephoneinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.prefillemailinfocall();
        }, 5000);
      } else {
        this.prefillemailinfocall();
      }
    },
    prefillemailinfocall() {
      this.showprephoneinfoloader = false;
      if (this.prefillresponse.data.email_info != "") {
        this.pre_email_info = this.prefillresponse.data.email_info;
        this.showpreemaildetailloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.prefilladdressinfocall();
        }, 5000);
      } else {
        this.prefilladdressinfocall();
      }
    },
    prefilladdressinfocall() {
      this.showpreemaildetailloader = false;
      if (this.prefillresponse.data.address_info.length != 0) {
        this.pre_address_info = this.prefillresponse.data.address_info;
        this.showpreaddressdetailloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.prefillpaninfocall();
        }, 5000);
      } else {
        this.prefillpaninfocall();
      }
    },
    prefillpaninfocall() {
      this.showpreaddressdetailloader = false;
      if (this.prefillresponse.data.identity_info.pan_number.length != 0) {
        this.pre_pan_info = this.prefillresponse.data.identity_info.pan_number;
        this.showprepandetailloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.prefillaadhaarinfocall();
        }, 5000);
      } else {
        this.prefillaadhaarinfocall();
      }
    },
    prefillaadhaarinfocall() {
      this.showprepandetailloader = false;
      if (this.prefillresponse.data.identity_info.aadhaar_number.length != 0) {
        this.pre_aadhaar_info = this.prefillresponse.data.identity_info.aadhaar_number;
        this.showpreaadhaardetailloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.prefillpassportinfocall();
        }, 5000);
      } else {
        this.prefillpassportinfocall();
      }
    },
    prefillpassportinfocall() {
      this.showpreaadhaardetailloader = false;
      if (this.prefillresponse.data.identity_info.passport_number.length != 0) {
        this.pre_passport_info = this.prefillresponse.data.identity_info.passport_number;
        this.showprepassportinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.prefillrationinfocall();
        }, 5000);
      } else {
        this.prefillrationinfocall();
      }
    },
    prefillrationinfocall() {
      this.showprepassportinfoloader = false;
      if (this.prefillresponse.data.identity_info.ration_card.length != 0) {
        this.pre_ration_card_info = this.prefillresponse.data.identity_info.ration_card;
        this.showprerationcardinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.prefillotherinfocall();
        }, 5000);
      } else {
        this.prefillotherinfocall();
      }
    },
    prefillotherinfocall() {
      this.showprerationcardinfoloader = false;
      if (this.prefillresponse.data.identity_info.other_id.length != 0) {
        this.pre_other_id_info = this.prefillresponse.data.identity_info.other_id;
        this.showpreotheridinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.fulldrivinginfocall();
        }, 5000);
      } else {
        this.fulldrivinginfocall();
      }
    },
    fulldrivinginfocall() {
      this.showpreotheridinfoloader = false;
      if (this.filldrvingdetailsresponse != "") {
        this.showfulldrivinginfo = this.filldrvingdetailsresponse;
        this.showpredrivinglicenseinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.pantodinnumbercall();
        }, 5000);
      } else {
        this.pantodinnumbercall();
      }
    },
    pantodinnumbercall() {
      this.showpredrivinglicenseinfoloader = false;
      if (this.dindetailsresponse != "") {
        this.showpantodininfo = this.dindetailsresponse;
        this.showpantodininfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.dininfocall();
        }, 5000);
      } else {
        this.dininfocall();
      }
    },
    dininfocall() {
      this.showpantodininfoloader = false;
      if (this.filldindetailsresponse != "") {
        this.showdindetailinfo = this.filldindetailsresponse;
        this.showdindetailinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.voterinfocall();
        }, 5000);
      } else {
        this.voterinfocall();
      }
    },
    voterinfocall() {
      this.showdindetailinfoloader = false;
      if (this.ecivoterinforesponse != "") {
        this.showvoterdetailinfo = this.ecivoterinforesponse;
        this.showvoterdetailinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.uannumberinfocall();
        }, 5000);
      } else {
        this.uannumberinfocall();
      }
    },
    uannumberinfocall() {
      this.showvoterdetailinfoloader = false;
      if (this.uaninforesponse != "") {
        this.showuannumberinfo = this.uaninforesponse;
        this.showuannumberinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.upinumberinfocall();
        }, 5000);
      } else {
        this.upinumberinfocall();
      }
    },
    upinumberinfocall() {
      this.showuannumberinfoloader = false;
      if (this.upidataresponse != "") {
        this.showupinumberinfo = this.upidataresponse;
        this.showupinumberinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.employmenthistoryinfocall();
        }, 5000);
      } else {
        this.employmenthistoryinfocall();
      }
    },
    employmenthistoryinfocall() {
      this.showupinumberinfoloader = false;
      if (
        this.uanhistoryresponse != "" &&
        this.uanhistoryresponse.data.employment_history.length != 0
      ) {
        this.showemploymenthistoryinfo = this.uanhistoryresponse.data.employment_history;
        this.showemploymenthistoryloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptoaadhaarlinkstatuscall();
        }, 5000);
      } else {
        this.poptoaadhaarlinkstatuscall();
      }
    },
    poptoaadhaarlinkstatuscall() {
      this.showemploymenthistoryloader = false;
      if (this.paninallresponse && this.paninallresponse.result.aadhaar_linked != "") {
        if (this.paninallresponse.result.aadhaar_linked == true) {
          this.popshowlinkstatus = "Yes";
        } else if (this.paninallresponse.result.aadhaar_linked == false) {
          this.popshowlinkstatus = "No";
        } else {
          this.popshowlinkstatus = "N/A";
        }
        this.popaadhaarlinkloader = true;
        this.deepsearchinglabel = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptofulladdresscall();
        }, 5000);
      } else {
        this.poptofulladdresscall();
      }
    },
    poptofulladdresscall() {
      this.popaadhaarlinkloader = false;
      if (this.paninallresponse && this.paninallresponse.result.address != "") {
        this.popshowaddress = this.paninallresponse.result.address;
        this.popaddressloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptodincompinfocall();
        }, 5000);
      } else {
        this.poptodincompinfocall();
      }
    },
    poptodincompinfocall() {
      this.popaddressloader = false;
      if (this.paninallresponse && this.paninallresponse.result.din_info.din != "") {
        this.popshowdincompany = this.paninallresponse.result.din_info;
        this.popdincompanyloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptodateofbirthinfocall();
        }, 5000);
      } else {
        this.poptodateofbirthinfocall();
      }
    },
    poptodateofbirthinfocall() {
      this.popdincompanyloader = false;
      if (this.paninallresponse && this.paninallresponse.result.dob != "") {
        this.popshowdateofbirth = this.paninallresponse.result.dob;
        this.popbirthdateloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptoemailcall();
        }, 5000);
      } else {
        this.poptoemailcall();
      }
    },
    poptoemailcall() {
      this.popbirthdateloader = false;
      if (this.paninallresponse && this.paninallresponse.result.email != "") {
        this.popshowemailaddress = this.paninallresponse.result.email;
        this.popemailidloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptofullnamecall();
        }, 5000);
      } else {
        this.poptofullnamecall();
      }
    },
    poptofullnamecall() {
      this.popemailidloader = false;
      if (this.paninallresponse && this.paninallresponse.result.full_name != "") {
        this.popshowfullname = this.paninallresponse.result.full_name;
        this.popfullnameloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptogendercall();
        }, 5000);
      } else {
        this.poptogendercall();
      }
    },
    poptogendercall() {
      this.popfullnameloader = false;
      if (this.paninallresponse && this.paninallresponse.result.gender != "") {
        this.popshowgender = this.paninallresponse.result.gender;
        this.popgenderloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptodirectorinfocall();
        }, 5000);
      } else {
        this.poptodirectorinfocall();
      }
    },
    poptodirectorinfocall() {
      this.popgenderloader = false;
      if (
        this.paninallresponse &&
        this.paninallresponse.result.is_director.info.length != 0
      ) {
        this.popshowdirectorhistory = this.paninallresponse.result.is_director.info;
        this.popdirectorinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptosoleproprietorinfocall();
        }, 5000);
      } else {
        this.poptosoleproprietorinfocall();
      }
    },
    poptosoleproprietorinfocall() {
      this.popdirectorinfoloader = false;
      if (
        this.paninallresponse &&
        this.paninallresponse.result.is_sole_proprietor.info.length != 0
      ) {
        this.popshowproprietorhistory = this.paninallresponse.result.is_sole_proprietor.info;
        this.popproprietorinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptomaskedaadharinfocall();
        }, 5000);
      } else {
        this.poptomaskedaadharinfocall();
      }
    },
    poptomaskedaadharinfocall() {
      this.popproprietorinfoloader = false;
      if (this.paninallresponse && this.paninallresponse.result.masked_aadhaar != "") {
        this.popshowmaskedaadhaar = this.paninallresponse.result.masked_aadhaar;
        this.popmaskedaadhaarinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptopaninfocall();
        }, 5000);
      } else {
        this.poptopaninfocall();
      }
    },
    poptopaninfocall() {
      this.popmaskedaadhaarinfoloader = false;
      if (this.paninallresponse && this.paninallresponse.result.pan_number != "") {
        this.popshowpannumber = this.paninallresponse.result.pan_number;
        this.poppannumberinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.poptophoneinfocall();
        }, 5000);
      } else {
        this.poptophoneinfocall();
      }
    },
    poptophoneinfocall() {
      this.poppannumberinfoloader = false;
      if (this.paninallresponse && this.paninallresponse.result.phone_number != "") {
        this.popshowphonenumber = this.paninallresponse.result.phone_number;
        this.popphonenumberinfoloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.rcnumberinfocall();
        }, 5000);
      } else {
        this.rcnumberinfocall();
      }
    },
    rcnumberinfocall() {
      this.popphonenumberinfoloader = false;
      if (
        this.rcnumberdetailinallresponse != "" &&
        this.rcnumberdetailinallresponse.data.rc_number.length != 0
      ) {
        this.showrcnumberhistoryinfo = this.rcnumberdetailinallresponse.data.rc_number;
        this.showrcnumberhistoryloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.rcnumberdetailsinfocall();
        }, 5000);
      } else {
        this.rcnumberdetailsinfocall();
      }
    },
    rcnumberdetailsinfocall() {
      this.showrcnumberhistoryloader = false;
      if (this.rcdetailinallresponse != "" && this.rcdetailinallresponse.length != 0) {
        this.showrcnumberdetailhistoryinfo = this.rcdetailinallresponse;
        this.showrcnumberdetailhistoryloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.udyamdetailscall();
        }, 5000);
      } else {
        this.udyamdetailscall();
      }
    },
    udyamdetailscall() {
      this.showrcnumberdetailhistoryloader = false;
      if (
        this.udyamdetailinallresponse != "" &&
        this.udyamdetailinallresponse.length != 0
      ) {
        this.showudyamdetailhistoryinfo = this.udyamdetailinallresponse;
        this.showudyamdetailhistoryloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.creditaddressdetailscall();
        }, 5000);
      } else {
        this.creditaddressdetailscall();
      }
    },
    creditaddressdetailscall() {
      this.showudyamdetailhistoryloader = false;
      if (
        this.creditdetailaddressresponse != "" &&
        this.creditdetailaddressresponse.length != 0
      ) {
        this.creditdetailaddressinfo = JSON.parse(this.creditdetailaddressresponse.data);
        console.log(this.creditdetailaddressinfo);
        this.creditdetailaddressloader = true;
        this.scrollbottom();
        setTimeout(() => {
          this.creditdetailaddressloader = false;
          this.deepsearchinglabel = false;
          this.scrollbottom();
        }, 5000);
      }
    },
    scrollbottom() {
      setTimeout(function () {
        var scrollableDiv = document.getElementById("scrollableDiv");
        var bottomElement = scrollableDiv.lastElementChild;
        bottomElement.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 1000);
    },
    printToPdfdata() {
      let fields = {};
      fields["mobile_number"] = this.searchmobile;
      if (this.firstname) {
        fields["name"] = this.firstname;
      }
      fields["response"] = this.masterdata;
      fields["response_alt_mob"] = this.masterdata_alt_mob;
      this.printbtnloader = true;
      this.ApiService.allrecordspdf(fields).then((data) => {
        if (data.success == true) {
          this.printbtnloader = false;
          const urlpath = data.url;
          window.open(urlpath, "_blank");
        } else {
          this.printbtnloader = false;
        }
      });
    },
    deletelocaldata() {
      let fields = {};
      fields["mobile_number"] = this.searchmobile;
      this.ApiService.deleteprefilldata(fields).then((data) => {
        this.localbtnloader = true;
        if (data.status === true) {
          this.localbtnloader = false;
          this.mobiledetailshowflag = false;
          this.searchmobile = "";
          var successMsg = "Deleted successfully";
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.localdatafound = false;
          this.disabledinput = false;
        } else {
          this.localbtnloader = false;
        }
      });
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("formSearchMobileNoInput");
      if (
        (mobileInput.value == "" || mobileInput.length > 1) &&
        (keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)
      ) {
        $event.preventDefault();
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return "N/A";
      }
    },
    format_monthyear(value) {
      if (value) {
        return moment(String(value)).format("MM/YYYY");
      } else {
        return "N/A";
      }
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    searchvoterclearbtn() {
      this.searchmobile = "";
      this.btnloader = false;
      this.disabledinput = false;
      this.mobiledetailshowflag = false;
      this.uanhistoryresponse = "";
      this.paninallresponse = "";
      this.rcnumberdetailinallresponse = "";
      this.rcdetailinallresponse = "";
      this.inquiry_request_info = "";
      this.ccr_response = "";
      this.identity_info_email_address_info = [];
      this.id_and_contact_info = "";
      this.nodatafoundflag = false;
      this.localdatafound = false;
      this.localbtnloader = false;
      this.upidataresponse = "";
      this.prefillresponse = "";
      this.filldrvingdetailsresponse = "";
      this.dindetailsresponse = "";
      this.filldindetailsresponse = "";
      this.uaninforesponse = "";
      this.ecivoterinforesponse = "";
      this.firstname = "";
      this.masterdata = "";
      this.clearbtnflag = false;
      this.printbtnloader = false;
      this.pre_personal_info = "";
      this.showprepersonalinfoloader = false;
      this.pre_phone_info = "";
      this.showprephoneinfoloader = false;
      this.pre_email_info = "";
      this.showpreemaildetailloader = false;
      this.pre_address_info = "";
      this.showpreaddressdetailloader = false;
      this.pre_pan_info = "";
      this.showprepandetailloader = false;
      this.pre_aadhaar_info = "";
      this.showpreaadhaardetailloader = false;
      this.pre_passport_info = "";
      this.showprepassportinfoloader = false;
      this.pre_ration_card_info = "";
      this.showprerationcardinfoloader = false;
      this.pre_other_id_info = "";
      this.showpreotheridinfoloader = false;
      this.showfulldrivinginfo = "";
      this.showpredrivinglicenseinfoloader = false;
      this.showpantodininfo = "";
      this.showpantodininfoloader = false;
      this.showdindetailinfo = "";
      this.showdindetailinfoloader = false;
      this.showvoterdetailinfo = "";
      this.showvoterdetailinfoloader = false;
      this.showuannumberinfo = "";
      this.showuannumberinfoloader = false;
      this.showupinumberinfo = "";
      this.showupinumberinfoloader = false;
      this.showemploymenthistoryinfo = "";
      this.showemploymenthistoryloader = false;
      this.popshowlinkstatus = "";
      this.popaadhaarlinkloader = false;
      this.popshowaddress = "";
      this.popaddressloader = false;
      this.popshowdincompany = "";
      this.popdincompanyloader = false;
      this.popshowdateofbirth = "";
      this.popbirthdateloader = false;
      this.popshowemailaddress = "";
      this.popemailidloader = false;
      this.popshowfullname = "";
      this.popfullnameloader = false;
      this.popshowgender = "";
      this.popgenderloader = false;
      this.popshowdirectorhistory = "";
      this.popdirectorinfoloader = false;
      this.popshowproprietorhistory = "";
      this.popproprietorinfoloader = false;
      this.popshowmaskedaadhaar = "";
      this.popmaskedaadhaarinfoloader = false;
      this.popshowpannumber = "";
      this.poppannumberinfoloader = false;
      this.popshowphonenumber = "";
      this.popphonenumberinfoloader = false;
      this.showrcnumberhistoryinfo = "";
      this.showrcnumberhistoryloader = false;
      this.showrcnumberdetailhistoryinfo = "";
      this.showrcnumberdetailhistoryloader = false;
      this.deepsearchinglabel = false;
      this.udyamdetailinallresponse = "";
      this.showudyamdetailhistoryinfo = "";
      this.showudyamdetailhistoryloader = false;
      (this.creditdetailaddressresponse = ""),
        (this.creditdetailaddressinfo = ""),
        (this.creditdetailaddressloader = false);
      this.pancardnumber = "";
      this.errpan = "";
      this.errmsg = "";
      this.errStatus = false;
    },
    validatePanCardNumber(val) {
      this.errStatus = false;
      if (!this.validPAN(val)) {
        this.errpan = "Please enter valid pan number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errpan = "";
        return true;
      }
    },

    validPAN: function (e) {
      var re = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
      return re.test(e);
    },
  },
};
</script>
<style scoped>
.form-box-outer {
  padding: 16px 16px;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
}

.search-voter-section-outer::-webkit-scrollbar {
  display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.search-tab-outer-scroll {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
  display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.voter-exit-box-outer {
  padding: 16px 16px 0;
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  transition: 0.5s;
  animation: showdiv 2s linear;
  position: relative;
}

@keyframes showdiv {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.customer-label-group {
  margin-bottom: 20px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.individual-header-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.52px;
  color: #0d488b;
  line-height: 15px;
}

.dividerrowhide .p-divider {
  display: none;
}

.form-submit-btn.btn {
  background: #ef8080 0% 0% no-repeat padding-box;
  border-color: #de2525;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 15px;
  font-family: "AcuminPro-Regular";
  min-width: 110px;
}

.form-submit-btn:focus {
  box-shadow: none;
}

.occupation-label {
  color: #0d488b;
  font-size: 14px;
}

.occupation-value {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  padding: 5px 7px;
  border-radius: 3px;
  letter-spacing: 0.52px;
  color: #0d488b;
  font-weight: 600;
  font-size: 14px;
}

.blink-occupation-value {
  animation: blinker 1s linear infinite;
  opacity: 0;
}

@keyframes blinker {
  from,
  49.9% {
    opacity: 0;
  }

  50%,
  to {
    opacity: 1;
  }
}

.print-submit-btn.btn {
  background: #0d6efd 0% 0% no-repeat padding-box;
  border-color: #0d6efd;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 15px;
  font-family: "AcuminPro-Regular";
  min-width: 110px;
}

.print-submit-btn:focus {
  box-shadow: none;
}
</style>
